<template>
    <section>
        <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="auth-form-light text-left p-5">
                                <div class="brand-logo text-center">
                                    <img src="@/assets/images/import/logo-mini.png" alt="">
                                </div>
                                <h4 class="text-center mt-4">Vous êtes nouveau?</h4>
                                <h6 class="font-weight-light text-center">L'inscription est facile et se fait en quelques étapes</h6>
                            </div>
                            <form class="pt-3" @submit.prevent="handleSubmit">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <h4>Informations</h4>
                                        <b-form-group id="pseudo" label="Nom d'utilisateur (pseudo)" label-for="pseudo">
                                            <b-form-input
                                            id="pseudo"
                                            name="pseudo"
                                            v-model="pseudo"
                                            :state="$v.pseudo.$dirty ? !$v.pseudo.$error : null"
                                            aria-describedby="pseudo"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="pseudo">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- email -->
                                        <b-form-group id="email" label="email" label-for="email">
                                            <b-form-input
                                            type="email"
                                            id="email"
                                            name="email"
                                            v-model="email"
                                            :state="$v.email.$dirty ? !$v.email.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- password -->
                                        <b-form-group id="password" label="Mot de passe" label-for="password">
                                            <b-form-input
                                            type="password"
                                            id="password"
                                            name="password"
                                            v-model="password"
                                            :state="$v.password.$dirty ? !$v.password.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- confirmation password -->
                                        <b-form-group id="confirmPassword" label="Confirmer le mot de passe" label-for="confirmPassword">
                                            <b-form-input
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            v-model="confirmPassword"
                                            :state="$v.confirmPassword.$dirty ? !$v.confirmPassword.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Les mots de passe ne sont pas identiques
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- firstname -->
                                        <b-form-group id="firstname" label="Nom" label-for="firstname">
                                            <b-form-input
                                            id="firstname"
                                            name="firstname"
                                            v-model="firstname"
                                            :state="$v.firstname.$dirty ? !$v.firstname.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- lastname -->
                                        <b-form-group id="lastname" label="Prénom" label-for="lastname">
                                            <b-form-input
                                            id="lastname"
                                            name="lastname"
                                            v-model="lastname"
                                            :state="$v.lastname.$dirty ? !$v.lastname.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- tel -->
                                        <b-form-group id="tel" label="Numéro téléphone" label-for="tel">
                                            <b-form-input
                                            id="tel"
                                            name="tel"
                                            v-model="tel"
                                            :state="$v.tel.$dirty ? !$v.tel.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <h4>Adresse</h4>
                                        <!-- adresse adresse 1 -->
                                        <b-form-group id="wayAddress" label="Adresse" label-for="wayAddress">
                                            <b-form-input
                                            id="wayAddress"
                                            name="wayAddress"
                                            v-model="wayAddress"
                                            :state="$v.wayAddress.$dirty ? !$v.wayAddress.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- adresse adresse 2 -->
                                        <b-form-group id="wayAddress2" label="Complément d'adresse" label-for="wayAddress2">
                                            <b-form-input
                                            id="wayAddress2"
                                            name="wayAddress2"
                                            v-model="wayAddress2"
                                            :state="$v.wayAddress2.$dirty ? !$v.wayAddress2.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                        
                                        <!-- adresse ville -->
                                        <b-form-group id="cityAddress" label="Ville" label-for="cityAddress">
                                            <b-form-input
                                            id="cityAddress"
                                            name="cityAddress"
                                            v-model="cityAddress"
                                            :state="$v.cityAddress.$dirty ? !$v.cityAddress.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- Adresse code postal -->
                                        <b-form-group id="postalCode" label="Code Postal" label-for="postalCode">
                                            <b-form-input
                                            id="postalCode"
                                            name="postalCode"
                                            v-model="postalCode"
                                            :state="$v.postalCode.$dirty ? !$v.postalCode.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- adresse département  -->
                                        <!-- <b-form-group id="departmentAddress" label="Département" label-for="departmentAddress">
                                            <b-form-input
                                            id="departmentAddress"
                                            name="departmentAddress"
                                            v-model="departmentAddress"
                                            :state="$v.departmentAddress.$dirty ? !$v.departmentAddress.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group> -->

                                        <!-- adresse pays  -->
                                        <b-form-group id="countryAddress" label="Pays" label-for="countryAddress">
                                            <b-form-select  id="countryAddress" name="countryAddress" v-model="countryAddress"
                                            :state="$v.countryAddress.$dirty ? !$v.countryAddress.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            class="mb-3">
                                                <b-form-select-option
                                                v-for="(country,country_id) in countries" :key="country_id"
                                                :value="country.translations.fr"
                                                >
                                                <img :src="country.flags.png" alt="">{{country.translations.fr}}
                                                </b-form-select-option>
                                            </b-form-select>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- newsletter -->
                                        <!-- <b-form-group class="mt-3" label="Newsletter" label-for="newsletter">
                                            <b-form-checkbox
                                            id="newsletter"
                                            v-model="newsletter"
                                            name="newsletter"
                                            >
                                            <p>Oui, je souhaite profiter pleinement de Mon échappée belle et recevoir des e-mails comportant des offres exclusives, des recommandations personnalisées et des astuces ! </p> 
                                            </b-form-checkbox>
                                        </b-form-group> -->
                                    </div>
                                </div>
                                

                              <div class="mt-3">
                                  <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">S'inscrire</button>
                              </div>
                              <div class="text-center mt-4 font-weight-light">
                                  Vous avez déjà un compte? <router-link to="/login" class="text-primary">se connecter</router-link>
                              </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { validationMixin } from 'vuelidate'
import { required,  sameAs, email} from 'vuelidate/lib/validators'
import pageLoader from '../components/pageLoader.vue'
export default {
    name: 'signin',
    mixins: [validationMixin],
    components:{
        pageLoader
    },
    data () {
        return {
            pseudo:'',
            email:'',
            password:'',
            confirmPassword:'',
            firstname:'',
            lastname:'',
            tel:'',
            wayAddress:'',
            wayAddress2:'',
            cityAddress:'',
            departmentAddress:'',
            countryAddress:'',
            postalCode:'',
            error: 0,
            newsletter: true,
            loadershow:false,
            countries:[]
        }
    },
    validations: {
        pseudo: {
            required
        },
        email: {
            required,
            email
        },
        password: {
            required
        },
        confirmPassword: {
            required,
          sameAs: sameAs('password')
        },
        firstname: {
            required
        },
        lastname: {
            required
        },
        tel: {
            required
        },
        wayAddress:{
          required
        },
        wayAddress2:{
        },
        cityAddress: {
          required
        },
        countryAddress: {
          required
        },
        postalCode: {
          required
        }
    },
    methods: {
        handleSubmit() {
            this.$v.$touch()
            if (this.$v.$anyError) {
                return
            }
            this.postMyInfos()
        },
        postMyInfos () {
        this.loadershow =true
        console.log('post my infos');
          axios.post('myinfos',
            {
                pseudo:this.pseudo,
                email:this.email,
                password:this.password,
                roles:["ROLE_USER"],
                firstname:this.firstname,
                lastname:this.lastname,
                newsletter: this.newsletter,
                tel:this.tel,
                link_code_page: "https://monechappeebelle.fr/verification/",
                address:{
                    mainaddress: {
                        line1: this.wayAddress,
                        line2: this.wayAddress2,
                        // Voie:this.wayAddress,
                        city:this.cityAddress,
                        state:this.postalCode,
                        country:this.countryAddress,
                        postal_code : this.postalCode
                    },
                    billingaddress: {
                        line1: this.wayAddress,
                        line2: this.wayAddress2,
                        // Voie:this.wayAddress,
                        city:this.cityAddress,
                        state:this.postalCode,
                        country:this.countryAddress,
                        postal_code : this.postalCode
                    },
                    deliveryaddress: {
                        line1: this.wayAddress,
                        line2: this.wayAddress2,
                        // Voie:this.wayAddress,
                        city:this.cityAddress,
                        state:this.postalCode,
                        country:this.countryAddress,
                        postal_code : this.postalCode
                    }
                }
            })
            .then(res=> {
              console.log(res),
              localStorage.setItem('ulid',res.data.ulid)
              this.$swal({
                type: 'success',
                title: 'Inscription réussie',
                confirmButtonColor: '#c19c28',
                html:
                'Un email vous sera envoyé pour la validation de votre compte <br>' ,
                footer: '<span class="text-right text-muted">En cas de problème, merci de nous contacter sur support@ailem.fr</span>'
            })
              this.$router.push({
                name:'home',
                params: {
                    ulid: res.data.ulid
                }
              })
            })
            .catch(err => {
                this.error++

                if(err.response.data.includes("pseudo")){
                    this.$swal.fire({
                        title: 'Oups...',
                        confirmButtonColor: '#c19c28',
                        confirmButtonText: 'Changer le nom d\'utilisateur',
                        text: 'Le nom d\'utilisateur "'+ this.pseudo +'" que vous avez choisi a déjà été utilisé.',
                        footer: '<a href="https://monechappeebelle.fr/login/">Se connecter</a>'
                    })
                // alert("Le nom d'utilisateur que vous avez choisi est déjà utilisé")
                this.loadershow=false
                return
                }
                else if(err.response.data.includes("email")){
                    this.$swal.fire({
                        confirmButtonColor: '#c19c28',
                        confirmButtonText: 'Changer l\'adresse email',
                        text: 'L\'adresse email que vous avez choisi est déjà utilisée',
                        footer: '<a href="https://monechappeebelle.fr/login/">Se connecter</a>'
                    })
                    // alert("L'adresse email est déjà utilisée ou comporte une erreur")
                    this.loadershow=false
                    return
                }else{
                    // this.loadershow=false
                    // alert('Votre inscription comporte une erreur-1')
                    this.error==1 ? this.postMyInfos() : alert('Votre inscription comporte une erreur-2')
                    this.loadershow=false
                }

              console.log(err)})
        },
        getCountries(){
            axios.get('https://restcountries.com/v2/all')
            .then(resCountries => {
                this.countries = resCountries.data
                console.log(this.countries);
            })
            .catch(errCountries => {
                errCountries
            })
        }
    },
    mounted(){
        this.getCountries()
        
    }
}
</script>